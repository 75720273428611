import BioData from "../data/Bio";

export const Bio = () => {
    return (
        <div className="App-page-content App-page-bio">
            <div className="flex flex-gap flex-center">
                <img src={BioData.image.source.default} alt={BioData.image.caption} />
                <span className="textbox">
                    {BioData.text}
                </span>
            </div>
        </div>
    );
}

export default Bio;