import VideosData from "../data/Videos";
import useScript from "../hooks/useScript";

export const Video = () => {
    useScript("https://apis.google.com/js/platform.js");

    return (
        <div className="App-page-content App-page-video">
            <div className="flex flex-wrap flex-center-responsive">
                {VideosData.map((data) => {
                    return (
                        <figure className="content-tile" key={data.link + data.caption}>
                            <iframe
                                src={data.link}
                                title={data.caption}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            />
                            <figcaption>{data.caption}</figcaption>
                        </figure>
                    )
                })}
                <div className="youtube-subscribe">
                    <div className="full-height flex flex-gap flex-center flex-column contentbox">
                        <span>Please like and subscribe for more:</span>
                        <div className="g-ytsubscribe" data-channelid="UCon-k-qAI7uR8VwRiA80Y6A" data-layout="full" data-count="default"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Video;