// Do not touch!
import * as Types from "../types";

// Add assets: pictures, videos, documents here!
import * as ResumePreview from '../assets/Resume.jpg';
import * as ResumeDocument from '../assets/Erin Herrick Resume.pdf';

// Content which will be visible on your site's Resume Page:
export const ResumeData: Types.ResumeDataType = {
    image: {
        source: ResumePreview,
        caption: "Erin Herrick's Resume"
    },
    document: {
        source: ResumeDocument
    }
};

// Do not touch!
export default ResumeData;