// Do not touch!
import * as Types from "../types";

// Add assets: pictures, videos, documents here!
import * as Erin1 from '../assets/Erin1.jpg';
import * as Erin2 from '../assets/Erin2.jpg';
import * as Erin3 from '../assets/Erin3.jpg';
import * as Erin4 from '../assets/Erin4.jpg';
import * as Erin6 from '../assets/Erin6.jpg';
import * as Erin7 from '../assets/Erin7.jpg';
import * as Erin8 from '../assets/Erin8.jpg';
import * as Erin9 from '../assets/Erin9.jpg';
import * as Erin10 from '../assets/Erin10.jpg';
import * as Production1 from '../assets/Production1.jpg';
import * as Production2 from '../assets/Production2.jpg';
import * as Production3 from '../assets/Production3.jpg';
import * as Production4 from '../assets/Production4.jpg';
import * as Production5 from '../assets/Production5.jpg';
import * as Production6 from '../assets/Production6.jpg';
import * as Production7 from '../assets/Production7.jpg';
import * as Production8 from '../assets/Production8.jpg';
import * as Production9 from '../assets/Production9.jpg';

// These are the categories which will be visible on the Photos page:
export enum GalleryType {
    Headshots = "Headshots",
    Productions = "Productions"
};

// Content which will be visible on your site's Photos Page:
export const PhotosData: Types.GalleryDataType = [{
        category: GalleryType.Headshots,
        images: [
            { source: Erin4, caption: "Michael Kushner Photography" },
            { source: Erin2, caption: "Michael Kushner Photography" },
            { source: Erin3, caption: "Michael Kushner Photography" },
            { source: Erin6, caption: "Michael Kushner Photography" },
            { source: Erin1, caption: "Michael Kushner Photography" },
            { source: Erin7, caption: "Michael Kushner Photography" },
            { source: Erin8, caption: "Michael Kushner Photography" },
            { source: Erin9, caption: "Michael Kushner Photography" },
            { source: Erin10, caption: "Self Portrait" }
        ]
    }, {
        category: GalleryType.Productions,
        images: [
            { source: Production1, caption: `"Spring Awakening" in Concert at 54 Below` },
            { source: Production2, caption: `"Cinderella" at the 5th Ave Theatre` },
            { source: Production3, caption: `"Cinderella" at Centerstage Seattle` },
            { source: Production4, caption: `"Aladdin" at Educational Theatre Inc` },
            { source: Production5, caption: `"25th...Putnam County Spelling Bee"` },
            { source: Production6, caption: `"All Shook Up"` },
            { source: Production7, caption: `"Bat Boy: the Musical!"` },
            { source: Production8, caption: `"Bat Boy: the Musical!"` },
            { source: Production9, caption: `Urinetown: the Musical` }
        ]
    },
    // Add as many or as few as you like by copying everything contained in the: ,{}
    // or delete any entries to remove them from your site!
];

// Do not touch!
export default PhotosData;