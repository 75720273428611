import * as Download from '../assets/Download.gif';
import ResumeData from '../data/Resume';

export const Resume = () => {
    return (
        <div className="App-page-content App-page-resume">
            <img className="resume-preview" src={ResumeData.image.source.default} alt={ResumeData.image.caption} />
            <div className="flex flex-column flex-start">
                <img className="download" src={Download.default} alt="Download" />
                <a className="download button" href={ResumeData.document.source.default} target={"_blank"} rel={"noreferrer"}>
                    Download
                </a>
            </div>
        </div>
    );
}

export default Resume;