import classNames from "classnames";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Bio from "../pages/Bio";
import Home from "../pages/Home";
import Photos from "../pages/Photos";
import Resume from "../pages/Resume";
import Unknown from "../pages/Unknown";
import Video from "../pages/Video";

export type PageProps = {
    animation?: boolean
};

export const Page = ({animation}: PageProps) => {
    const animationState = animation ? "in" : "out";

    useEffect(() => {
        if(animation != null) {
            document.body.className = (`fade-background-${animationState}`);
            document.body.addEventListener("animationend", () => {
                document.body.classList.remove("fade-background-out");
            }, { once: true });
        }
    }, [animation, animationState])

    return (
        <main className={classNames("App-section App-page")}>
            <div className={classNames(animation != null && `fade-foreground-${animationState}`)}>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="bio" element={<Bio />} />
                    <Route path="resume" element={<Resume />} />
                    <Route path="photos" element={<Photos />} />
                    <Route path="video" element={<Video />} />
                    <Route path="*" element={<Unknown />} />
                </Routes>
            </div>
        </main>
    );
}

export default Page;