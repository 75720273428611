// Do not touch!
import * as Types from "../types";

// Add assets: pictures, videos, documents here!
import * as Erin1 from '../assets/ErinHome1.jpg';
import * as Erin2 from '../assets/ErinHome2.jpg';
import * as Erin3 from '../assets/ErinHome3.jpg';

// Content which will be visible on your site's Home Page:
export const HomeData: Types.LandingDataType = {
    images: [{
            source: Erin1,
            caption: "Headshot Smile"
        }, {
            source: Erin2,
            caption: "Headshot Casual"
        }, {
            source: Erin3,
            caption: "Headshot Serious"
        },
        // Add as many or as few as you like by copying everything contained in the: ,{}
        // or delete any entries to remove them from your site!
    ]
};

// Do not touch!
export default HomeData;