import { useState, EventHandler } from "react";
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import DelayLink from './DelayLink';

export type NavigationItemType = {
    text: string,
    link?: string,
    type?: string
};

export type NavigationType = NavigationItemType[];

export const NavigationMenu: NavigationType = [{
    text: "Erin Herrick",
    type: "Main"
}, {
    text: "Bio"
}, {
    text: "Resume"
}, {
    text: "Photos"
}, {
    text: "Video"
}];

export type NavigationAnimationType = {
    fadeIn?: EventHandler<any>,
    fadeOut?: EventHandler<any>
};

export type NavigationProps = {
    type?: string,
    animation?: NavigationAnimationType
}

export const Navigation = ({ type, animation }: NavigationProps) => {
    const location = useLocation();
    const currentPath = location.pathname.replace('/', '');
    const handleAnimation = () => {
        animation?.fadeOut?.(null);
        if(menuOpen) {
            setMenuOpen(false);
        }
    };
    const handleAnimationEnd = () => {
        animation?.fadeIn?.(null);
    };
    const isMain = type === "Main";
    
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    const Items = ({type} : {type?: string}) => {
        return NavigationMenu.filter(item => !type || !item.type || item.type === type).map(item => {
            const link = item.type === "Main" ? "/" : item.link || item.text.toLowerCase();
            return (
                <DelayLink
                    key={link}
                    className={classNames("navigation-link", currentPath === link && "active-link", item.type?.toLowerCase())}
                    to={link}
                    onDelayStart={handleAnimation}
                    onDelayEnd={handleAnimationEnd}
                    neverClear={true}
                    delay={500}
                >{item.text}</DelayLink>
            )
        })
    }

    return (
        <nav className={classNames("flex", type?.toLowerCase())}>
            <Items type={type} />
            { isMain &&
                <div className="menu collapsible">
                    <div className="flex interactable" onClick={handleMenuOpen}>
                        <div className="flex-grow flex-stretch">
                            <div className="flex flex-center menu-button">
                                <FontAwesomeIcon icon={menuOpen ? faWindowClose : faBars} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames("menu-content absolute flex flex-column", menuOpen && "open")}>
                        <Items type={"Collapsible"} />
                    </div>
                </div>
            }
        </nav>
    );
}

export default Navigation;