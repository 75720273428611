import HomeData from "../data/Home";

export const Home = () => {
    return (
        <div className="App-page-content App-page-home">
            <div className="flex flex-wrap flex-center">
                {HomeData.images.map((image) => {
                    return (
                        <img key={image.source.default + image.caption} src={image.source.default} alt={image.caption} />
                    );
                })}
            </div>
        </div>
    );
}

export default Home;