import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";

// Functional link component which delays page navigation
export const DelayLink = (props: any) => {
  const { delay, onDelayStart, onDelayEnd, replace, to, neverClear, children, ...rest } = props;
  let timeout: any = null;
  let history = useNavigate();
  let location = useLocation();
  const unchangedLocation = location?.pathname.replace("/", "") === to.replace("/", "");

  useEffect(() => {
    return () => {
      if (timeout && !neverClear) {
        clearTimeout(timeout);
      }
    };
  }, [neverClear, timeout]);

  const handleClick = (e: any) => {
    // if trying to navigate to current page stop everything
    if (unchangedLocation) return;

    onDelayStart(e, to);
    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    timeout = setTimeout(() => {
      if (replace) {
        history(to, { replace: true });
      } else {
        history(to);
      }
      onDelayEnd(e, to);
    }, delay);
  };

  return <Link {...rest} className={classNames(rest.className, unchangedLocation && "disabled")} to={to} onClick={handleClick}>{children}</Link>;
};

DelayLink.propTypes = {
  // Milliseconds to wait before registering the click.
  delay: PropTypes.number,
  // Called after the link is clicked and before the delay timer starts.
  onDelayStart: PropTypes.func,
  // Called after the delay timer ends.
  onDelayEnd: PropTypes.func,
  // Replace history or not
  replace: PropTypes.bool,
  // Link to go to
  to: PropTypes.string,
  neverClear: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
};

DelayLink.defaultProps = {
  to: PropTypes.string.isRequired,
  replace: false,
  delay: 0,
  onDelayStart: () => {},
  onDelayEnd: () => {},
};

export default DelayLink;