// Do not touch!
import * as Types from "../types";

// Content which will be visible on your site's Videos Page:
export const VideosData: Types.ContentGalleryDataType = [
    { 
        link: "https://www.youtube.com/embed/HRj_xMnll_g",
        caption: `Erin sings "What Baking Can Do" from Sara Bareilles' Waitress`
    }, {
        link: "https://www.youtube.com/embed/dITNCMlDPF4",
        caption: `Erin sings "Spring" from Poems & Moon Songs by Will Reynolds`
    }, {
        link: "https://www.youtube.com/embed/Lt-ZG_idPHM",
        caption: `Erin sings "Lost" from Fugitive Songs by Miller & Tysen`
    }, {
        link: "https://www.youtube.com/embed/CQ0tPZKvo0A",
        caption: `Erin sings "Love Will Come And Find Me Again" from Bandstand`
    }, {
        link: "https://www.youtube.com/embed/uxhUYTRo10s",
        caption: `Erin sings "I Wish It So" from Juno, a Musical by Marc Blitzstein`
    },
    // Add as many or as few as you like by copying everything contained in the: ,{}
    // or delete any entries to remove them from your site!
];

// Do not touch!
export default VideosData;