import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import * as Types from "../types";
import useKeyPress from "../hooks/useKeyPress";
import PhotosData from "../data/Photos";

export type SelectedGalleryImage = {
    category: Types.GalleryType,
    image?: Types.ImageType,
    fullscreen: boolean
};

export const Photos = () => {
    const [gallery, setGallery] = useState<SelectedGalleryImage>({
        category: Types.GalleryType.Headshots,
        image: PhotosData[0].images[0],
        fullscreen: false
    });
    const handleSetGallery = (type: Types.GalleryType) => {
        return () => setGallery({
            category: type,
            image: PhotosData.find((x) => x.category === type)?.images[0],
            fullscreen: false
        });
    };
    const setImage = useCallback((image: Types.ImageType) => {
        setLastImage(gallery.image || null);
        setGallery({
            category: gallery.category,
            image: image,
            fullscreen: gallery.fullscreen
        });
    }, [gallery]);
    const handleSetImage = useCallback((image: Types.ImageType) => {
        return () => setImage(image);
    }, [setImage]);
    const setFullscreen = useCallback((active?: boolean) => {
        const fullscreen = active != null ? active : !gallery.fullscreen;
        if(fullscreen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
        setGallery({
            category: gallery.category,
            image: gallery.image,
            fullscreen: fullscreen
        });
    }, [gallery]);
    const handleSetFullscreen = useCallback((active?: boolean) => {
        return () => setFullscreen(active);
    }, [setFullscreen]);
    const escape = useKeyPress("Escape");
    useEffect(() => {
        if(escape) {
            setFullscreen(false);
        }
    }, [escape, setFullscreen]);

    const [lastImage, setLastImage] = useState<Types.ImageType | null>(null);
    const handleLastImageFade = () => {
        setTimeout(function(){
            setLastImage(null);
        }, 500);
    };
    
    const handleSetImageIndex = useCallback((increment: number) => {
        const currentGallery = PhotosData.find((x) => x.category === gallery.category);
        const currentImageIndex = currentGallery?.images.findIndex((x) => x === gallery.image);
        let nextImageIndex = currentImageIndex != null ? currentImageIndex + increment : 0;
        if(currentGallery) {
            if(nextImageIndex > currentGallery.images.length - 1) {
                nextImageIndex = 0;
            } else if (nextImageIndex < 0) {
                nextImageIndex = currentGallery.images.length - 1;
            }
        }
        const newImage = currentGallery?.images[nextImageIndex];
        if(newImage) {
            setImage(newImage);
        }
    }, [gallery, setImage]);

    const handleLeftArrow = useCallback(() => {
        handleSetImageIndex(-1);
    }, [handleSetImageIndex]);
    const arrowLeft = useKeyPress("ArrowLeft");
    useEffect(() => {
        if(arrowLeft) {
            handleLeftArrow();
        }
    }, [arrowLeft, handleLeftArrow]);

    const handleRightArrow = useCallback(() => {
        handleSetImageIndex(+1);
    }, [handleSetImageIndex]);
    const arrowRight = useKeyPress("ArrowRight");
    useEffect(() => {
        if(arrowRight) {
            handleRightArrow();
        }
    }, [arrowRight, handleRightArrow]);

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            handleLeftArrow();
        } else if (isRightSwipe) {
            handleRightArrow();
        }
    }

    const Gallery = ({className} : { className: string }) => <>
        <div 
            className={classNames("center-horizontal full-width", className)}
            onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
        >
            <div className="flex flex-center flex-gap small">
                <div className="flex-grow flex-stretch">
                    <span className="flex flex-end flex-center flex-column full-height control interactable" onClick={handleLeftArrow}>&lt;</span>
                </div>
                { !gallery.fullscreen
                    ? <img
                        className={classNames("gallery-image overlap transparent", lastImage != null && "fade-foreground-out")}
                        src={lastImage?.source.default}
                        alt={lastImage?.caption}
                        onLoad={handleLastImageFade}
                    />
                    : <div className="absolute top right">
                        <span className="control close interactable" onClick={handleSetFullscreen(false)}>X</span>
                    </div>
                }
                <figure className="gallery-image">
                    <img
                        className={classNames(!gallery.fullscreen && "interactable")}
                        src={gallery.image?.source.default}
                        alt={gallery.image?.caption}
                        title={!gallery.fullscreen ? "Click/tap the image to enlarge it." : ""}
                        onClick={handleSetFullscreen(true)}
                    />
                    <figcaption>{gallery.image?.caption}</figcaption>
                </figure>
                <div className="flex-grow flex-stretch">
                    <span className="flex flex-start flex-center flex-column full-height control interactable" onClick={handleRightArrow}>&gt;</span>
                </div>
            </div>
        </div>
    </>;

    return (
        <div className="App-page-content App-page-photos">
            <div className={classNames("dark-background", gallery.fullscreen ? "always-visible" : "hidden")}>
                <Gallery className="full-screen" />
            </div>
            <div className="flex flex-gap full-width">
                <div className="flex flex-column gallery">
                    { PhotosData.find((x) => x.category === gallery.category)?.images.map((image) => {
                        return (
                            <img
                                key={image.source.default}
                                className={classNames("preview top-cropped interactable", image.source === gallery.image?.source && "active")}
                                onClick={handleSetImage(image)} src={image.source.default} alt={image.caption}
                            />
                        );
                    })}
                </div>
                <div className="gallery-viewer flex flex-column flex-start full-height flex-grow">
                    <nav className="categories center-horizontal">
                        <ul className="horizontal">
                            { Object.entries(Types.GalleryType).map(([key, value]) => {
                                return (
                                    <li key={key}>
                                        <h2 className={classNames("category button", gallery.category === value && "active")} onClick={handleSetGallery(value)}>{key}</h2>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    <br />
                    <Gallery className="normal-screen" />
                </div>
            </div>
        </div>
    );
}

export default Photos;