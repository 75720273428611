import { useState, useEffect } from 'react';
import useKeyState from './useKeyState';

export const useKeyPress = (targetKey: string) => {
    const keyState = useKeyState(targetKey);
    const [keyPressed, setKeyPressed] = useState<boolean | null>(null);
  
    useEffect(() => {
        if(keyState && keyPressed == null) {
            setKeyPressed(true);
        }
    }, [keyPressed, keyState]);

    useEffect(() => {
        if(keyPressed) {
            setKeyPressed(false);
        } else {
            setKeyPressed(null);
        }
    }, [keyPressed]);
  
    return keyPressed;
};

export default useKeyPress;