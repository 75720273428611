// Do not touch!
import * as Types from "../types";

// Add assets: pictures, videos, documents here!
import * as Erin4 from '../assets/Erin4.jpg';

// Content which will be visible on your site's Bio Page:
export const BioData: Types.SummaryDataType = {
    image: {
        source: Erin4,
        caption: "Erin Bio Headshot"
    },
    text: `Erin Herrick is a bicoastal actor, singer, dancer, and voiceover artist. Raised in Seattle, she earned her BA from the University of Washington with a double major in Drama and Music. Shortly after graduating, she moved to New York and began studying with top industry professionals while attending as much theatre as humanly possible. She currently splits her time between both coasts, going wherever her next gig takes her.\n
Erin was most recently seen onstage in Village Theatre’s production of Hello, Dolly. She has worked on various artistic projects including films, audiobooks, and a national tour that brought theatre to underserved elementary schools. Favorite theatrical roles include Shelley Parker (Bat Boy), Hope Cladwell (Urinetown), and Wendla (Spring Awakening in Concert).\n
In her spare time, Erin enjoys roller skating, drinking BOBA, improv comedy, and making friends with any cat she meets. She is an active member of the theatre communities in both Seattle and New York, where she often works as an Usher for Broadway productions. Above all else, Erin strives to create meaningful art that fosters connection and understanding in a world that would otherwise make us feel alone.\n`

};

// Do not touch!
export default BioData;
