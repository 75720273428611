import { SocialsData } from '../data/Header';

export type SocialProps = {
    type: string,
    link: string,
    image: string
};

export const Social = ({type, link, image} : SocialProps) => {
    return (
        <a href={link} target={"_blank"} rel={"noreferrer"}>
            <img src={image} alt={type} />
        </a>
    );
};

export const Socials = () => {
    return (
        <nav className="socials">
            <ul>
                {SocialsData.map((social) => {
                    return (
                        <li key={social.link}>
                            <Social type={social.image.caption} link={social.link} image={social.image.source.default} />
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
};

export default Socials;