// Do not touch!
import * as Types from "../types";

// Add assets: pictures, videos, documents here!
import * as Instagram from '../assets/Instagram.jpg';
import * as YouTube from '../assets/YouTube.jpg';
import * as Facebook from '../assets/Facebook.jpg';
import * as Twitter from '../assets/Twitter.jpg';
import * as Email from '../assets/Email.jpg';

// Links which will be visible on your site's header:
export const SocialsData: Types.SocialDataType = [
    {
        link: "https://www.instagram.com/erin.herrick/",
        image: {
            source: Instagram,
            caption: "Instagram"
        }
    }, {
        link: "https://www.youtube.com/@ErinHerrick1/videos",
        image: {
            source: YouTube,
            caption: "YouTube"
        }
    }, {
        link: "https://www.facebook.com/erin.herrick.3",
        image: {
            source: Facebook,
            caption: "Facebook"
        }
    }, {
        link: "https://twitter.com/ErinNHerrick",
        image: {
            source: Twitter,
            caption: "Twitter"
        }
    }, {
        link: "mailto: erin.herrick@gmail.com",
        image: {
            source: Email,
            caption: "Email"
        }
    },
    // Add as many or as few as you like by copying everything contained in the: ,{}
    // or delete any entries to remove them from your site!
];