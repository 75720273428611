import Navigation, { NavigationAnimationType } from "../components/Navigation";
import Socials from "../components/Socials";

export type HeaderProps = {
    animation?: {
        navigation?: NavigationAnimationType
    }
};

export const Header = ({animation} : HeaderProps) => {
    return (
        <header className="App-section App-header">
            <div className="flex">
                <Navigation type="Main" animation={animation?.navigation} />
            </div>
            <Socials />
        </header>
    );
}

export default Header;