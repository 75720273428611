import { useState, useEffect } from 'react';

export const useKeyState = (targetKey: string) => {
    const [keyState, setKeyState] = useState(false);
  
    useEffect(() => {
        const downHandler = (e: KeyboardEvent) => {
            if (e.key === targetKey) setKeyState(true);
        };
        
        const upHandler = (e: KeyboardEvent) => {
            if (e.key === targetKey) setKeyState(false);
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]);
  
    return keyState;
};

export default useKeyState;