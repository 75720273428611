import Navigation, { NavigationAnimationType } from "../components/Navigation";

export type FooterProps = {
    animation?: {
        navigation?: NavigationAnimationType
    }
};

export const Footer = ({animation}: FooterProps) => {
    return (
        <footer className="App-section App-footer">
            <div className="App-footer-content most-width flex">
                <Navigation type="Alternate" animation={animation?.navigation} />
                <span className="full-width right-align dim small">
                    © 2016-{new Date().getFullYear()} Erin Herrick.
                </span>
            </div>
        </footer>
    );
}

export default Footer;