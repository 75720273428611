import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./sections/Footer";
import Header from "./sections/Header";
import Page from "./sections/Page";

export const Layout = () => {
    const [animationState, setAnimationState] = useState<boolean | undefined>();
    const handleSetAnimationState = (state: boolean) => () => {
        setAnimationState(state);
    };
    const navigation = {fadeIn: handleSetAnimationState(true), fadeOut: handleSetAnimationState(false)};

    return (
        <div className="App">
            <BrowserRouter>
                <Header animation={{navigation}} />
                <Page animation={animationState} />
                <Footer animation={{navigation}} />
            </BrowserRouter>
        </div>
    );
}

export default Layout;